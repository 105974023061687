//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
	Toast
} from "vant";
import { bgimgUrl2 } from '/src/utils/config.js';
export default {
	name: "exercisecj",
	data() {
		return {
			frantion: 0,
			time: 0,
			bgimgUrl2:''
		}
	},
	created() {
		this.bgimgUrl2 = bgimgUrl2
		// 分数
		this.frantion = this.$route.query.frantion
		// 时间
		this.time = this.$route.query.time
		console.log(this.$route.query)
	},
	mounted() {
		this.getTime()
	},
	methods: {
		getTime() {
			if (this.time > 60) {
				this.time = this.time * 60 + "分钟"
			} else {
				this.time = this.time + "秒"
			}
		}
	},

}
